<template>
  <div>
    <el-form :inline="true" label-width="80px" size="mini">
      <el-form-item label="机会号码" >
        <el-input size="mini" v-model="form.telephone" placeholder="机会号码"></el-input>
      </el-form-item>
      <el-form-item label="机会ID" >
        <el-input size="mini" v-model="form.opporId" placeholder="机会ID"></el-input>
      </el-form-item>
      <el-form-item label="拨打坐席" prop="adviserId" size="mini">
        <el-select v-model="form.accountId" filterable class="condition-select" placeholder="请选择">
<!--          <el-option label="全部" value=""></el-option>-->
<!--          <el-option v-for="item in adviserList" :key="item.id" :label="item.nickName" :value="item.id">-->
<!--          </el-option>-->
          <el-option-group
                  v-for="group in adviserList"
                  :key="group.label"
                  :label="group.label">
            <el-option
                    v-for="item in group.data"
                    :key="item.id"
                    :label="item.nickName"
                    :value="item.id">
            </el-option>
          </el-option-group>
        </el-select>
      </el-form-item>
      <el-form-item label="部门" size="mini">
        <el-select v-model="form.deptId" filterable class="condition-select" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option v-for="item in deptList" :key="item.id" :label="item.deptName" :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="沟通状态" size="mini">
        <el-select v-model="form.status" filterable class="condition-select" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="未接通" :value="0"></el-option>
          <el-option label="已接通" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="拨打方式" size="mini">
        <el-select v-model="form.type" filterable class="condition-select" placeholder="请选择">
          <el-option label="全部" value=""></el-option>
          <el-option label="直呼" :value="0"></el-option>
          <el-option label="双呼" :value="1"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="线路商" size="mini">
        <el-input size="mini" v-model="form.channel" placeholder="线路商"></el-input>
      </el-form-item>
      <el-form-item label="主叫号码" size="mini">
        <el-input size="mini" v-model="form.callPhone" placeholder="主叫号码"></el-input>
      </el-form-item>
      <el-form-item label="工号" size="mini">
        <el-input size="mini" v-model="form.jobId" placeholder="工号"/>
      </el-form-item>
      <el-form-item label="拨打时间" size="mini">
        <el-date-picker v-model="form.dailTime" size="mini" type="daterange" range-separator="至"
                        start-placeholder="开始日期"
                        end-placeholder="结束日期">
        </el-date-picker>
      </el-form-item>
      <el-form-item size="mini">
        <el-button type="primary" size="mini" icon="el-icon-search" @click="doSearch"></el-button>
        <el-button icon="el-icon-refresh" size="mini" @click="resetQuery">重置</el-button>
      </el-form-item>
    </el-form>

    <el-row>
      <el-col :span="4">
        <el-button size="mini" type="primary" @click="exportTrafficRecord">导出</el-button>
      </el-col>
      <el-col :span="20">
        <span style="color:#F56C6C">默认展示当日拨打记录，若需查看其他时间拨打记录，请进行查询</span>
      </el-col>
    </el-row>
    <el-row>
      <el-table :data="tableData" max-height="506px" style="width: 100%" stripe fit v-loading="loading"
                :summary-method="getSummaries" show-summary>
        <el-table-column align="center" prop="type" label="拨打方式" width="100">
          <template v-slot="scope">
            <el-tag type="primary" v-if="scope.row.type === 0">直呼</el-tag>
            <el-tag type="success" v-else-if="scope.row.type === 1">双呼</el-tag>
            <el-tag type="warning" v-else>其他呼叫</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="channel" label="线路商" width="100"></el-table-column>
        <el-table-column align="center" prop="callPhone" label="主叫号码" width="100"></el-table-column>
        <el-table-column align="center" prop="opporId" label="机会ID" width="100"></el-table-column>
        <el-table-column align="center" prop="telephone" label="机会号码" width="150"></el-table-column>
        <el-table-column align="center" prop="callerName" label="拨打坐席" width="120"></el-table-column>
        <el-table-column align="center" prop="jobId" label="工号" width="120"/>
        <el-table-column align="center" prop="deptId" label="部门" width="150">
          <template v-slot="scope">
            <span v-for="item in deptList" v-if="scope.row.deptId === item.id">{{ item.deptName }}</span>
          </template>
        </el-table-column>
        <!--        <el-table-column align="center" prop="type" label="类型" width="100">-->
        <!--          <template v-slot="slot">-->
        <!--            <span v-if="slot.row.type=== 1">首咨</span>-->
        <!--            <span v-if="slot.row.type=== 2">个人</span>-->
        <!--            <span v-if="slot.row.type=== 3">常规</span>-->
        <!--            <span v-if="slot.row.type=== 4">库存</span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <!--        <el-table-column align="center" prop="mediatorName" label="来源渠道" width="125">-->
        <!--          <template slot-scope="slot">-->
        <!--            <span v-if="slot.row.mediatorName !== null" v-text="slot.row.mediatorName"></span>-->
        <!--            <span v-else v-text="'其它'"></span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->
        <el-table-column align="center" prop="createTime" label="拨打时间" width="150"></el-table-column>

        <el-table-column align="center" label="沟通状态" width="150">
          <template slot-scope="slot">
            <span v-if="slot.row.status === 0">未接通</span>
            <span v-else-if="slot.row.status === 1">已接通</span>
            <span v-else>--</span>
          </template>
        </el-table-column>

        <el-table-column align="center" prop="beginTime" label="接通时间" width="150"></el-table-column>

        <el-table-column align="center" label="沟通时长" width="150">
          <template slot-scope="slot">
            {{ callTimes(slot.row.duration) }}
          </template>
        </el-table-column>

        <el-table-column align="center" prop="finishTime" label="挂断时间" width="150"></el-table-column>
        <!--        <el-table-column align="center" prop="orderStatus" label="成交状态" width="125">-->
        <!--          <template slot-scope="scope">-->
        <!--            <span v-if="scope.row.orderStatus === 0">未成交</span>-->
        <!--            <span v-else-if="scope.row.orderStatus === 1">已成交</span>-->
        <!--            <span v-else-if="scope.row.orderStatus === 2">已下单</span>-->
        <!--            <span v-else>其他</span>-->
        <!--          </template>-->
        <!--        </el-table-column>-->

        <el-table-column align="center" label="操作" fixed="right" width="400">
          <template slot-scope="slot">
            <template v-if=" slot.row.record">
              <template v-if="slot.row.record.indexOf('http') !== -1">
                <audio controls="controls" :src="slot.row.record"></audio>
                <el-button type="primary" size="small" plain
                           @click="downLoads(slot.row.record)">打开播放
                </el-button>
              </template>
              <template slot-scope="slot" v-else>
                <audio controls="controls"
                       :src="'https://call.qinglanx.com:8443/pscc/sounds/record/' + slot.row.record">
                </audio>
                <el-tooltip class="item" effect="dark" content="点击下载录音" placement="bottom">
                  <el-button type="success" circle plain icon="el-icon-download"
                             style="vertical-align: top;margin-top: 8px"
                             @click="downloadRadio(`https://call.qinglanx.com:8443/pscc/sounds/record/${slot.row.record}`)">

                  </el-button>
                </el-tooltip>
              </template>
            </template>
            <template v-else>
              <span>暂未获取通话录音</span>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="page.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="page.size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="page.total">
      </el-pagination>
    </el-row>
  </div>

</template>

<script>
import * as api from "../../utils/api";
import {formatSeconds} from "../../utils/date-util";

import GgdCard from "../../components/GgdCard";
import axios from "axios";
import * as accountApi from "@/api/system/account";
import * as deptApi from '@/api/system/dept'
import dept from "@/views/system/dept/index.vue";

export default {
  name: "PersonInfo",
  computed: {
    dept() {
      return dept
    }
  },
  components: {GgdCard},
  data() {
    return {
      callSum: 0,
      opporSum: 0,
      passSum: 0,
      timeSum: 0,
      deptList: [],
      tableData: [],
      form: {
        dailTime: [new Date(), new Date()],
      },
      type: 0,
      page: {current: 1, size: 10, total: 0},
      loading: true,
      adviserList: [],
    }
  },
  directives: {
    getLink: {
      // 指令的定义
      inserted: function (el, val) {
        el.src = "https://call.qinglanx.com:8443/pscc/sounds/record/" + val.value;
      }
    }
  },
  methods: {
    sumTraffic() {
      api.sumTraffic(this.form).then(res => {
        if(res.success){
          this.callSum = res.data.callSum
          this.opporSum = res.data.opporSum
          this.passSum = res.data.passSum
          this.timeSum = res.data.timeSum
        }
      })
    },
    getSummaries(params) {
      const {columns, data} = params;
      return ['', '拨打总数:' + this.callSum, '机会总数:' + this.opporSum, '接通总数:' + this.passSum, '总时长:' + (this.timeSum ? this.timeSum : 0)];
    },
    findDeptChild() {
      deptApi.findChild().then(res => {
        if (res.success) {
          this.deptList = res.data
        }
      });
    },
    keyboard(e) {
      if (e.keyCode === 13) {
        this.doSearch()
      }
    },
    handleSizeChange(val) {
      this.page.size = val
      this.trafficRecordList(this.type, this.accountId)
    },
    handleCurrentChange(val) {
      this.page.current = val
      this.trafficRecordList(this.type, this.accountId)
    },
    doSearch: function () {
      this.page.current = 1
      this.trafficRecordList();
    },
    /**
     * 重置查询
     */
    resetQuery() {
      this.form = {}
      this.reload()
    },
    /**
     * 重新刷新页面
     * @param params
     */
    reload() {
      this.loading = true
      this.trafficRecordList()
    },
    trafficRecordList: function () {
      if (this.form.dailTime) {
        let dailTime = this.form.dailTime
        let dailTimeStart = dailTime[0].format("yyyy-MM-dd") + ' 00:00:00'
        let dailTimeEnd = dailTime[1].format("yyyy-MM-dd") + ' 23:59:59'
        this.form.startTime = dailTimeStart
        this.form.endTime = dailTimeEnd
      } else {
        this.form.dailTimeStart = ""
        this.form.dailTimeEnd = ""
      }
      this.sumTraffic()
      this.loading = true
      api.trafficRecordList(this.page.current, this.page.size, this.form).then(res => {
        let resEntity = res.data
        // this.page.current = res.data.length
        this.page.size = resEntity.size
        this.page.total = resEntity.total
        this.tableData = resEntity.records
        this.loading = false
      })
    },
    /**
     * 沟通时长
     * @param row
     */
    callTimes(duration) {
      return formatSeconds(duration)
    },
    getUrl(link) {
      return "https://call.qinglanx.com:8443/pscc/sounds/record/" + link
    },
    /**
     * 下载录音
     */
    downLoads(url) {
      if (url.indexOf('http') == -1) {
        var httpurl = "https://call.qinglanx.com:8443/pscc/sounds/record/"
        url += httpurl + url
      }
      var a = document.createElement('a');
      var httpurl = url;
      var filename = httpurl.slice(-10);
      a.href = httpurl;
      a.download = filename;
      a.target = '_blank'
      a.click();
      window.URL.revokeObjectURL(httpurl);
    },
    /**
     * 下载录音
     * */
    downloadRadio(href) {
      let index = href.lastIndexOf("\/");
      let str = href.substring(index + 1, href.length);
      fetch(href).then(res => res.blob()).then(blob => {
        const a = document.createElement('a');
        document.body.appendChild(a)
        a.style.display = 'none'
        // 使用获取到的blob对象创建的url
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        // 指定下载的文件名
        a.download = str;
        a.click();
        document.body.removeChild(a)
        // 移除blob对象的url
        window.URL.revokeObjectURL(url);
      });
    },
    initAdviser() {
      accountApi.adviserAll().then(res => {
        if (res.success) {
          this.adviserList = []
          const adviserList = res.data
          const adviserList1 = adviserList.filter(e => e.status === 1);
          const adviserList2 = adviserList.filter(e => e.status === 2);
          const obj1 = {
            label: '正常',
            data: adviserList1
          }
          const obj2 = {
            label: '离职',
            data: adviserList2
          }
          this.adviserList.push(obj1, obj2)
          console.log(this.adviserList)
        }
      })
    },
    exportTrafficRecord() {
      if (this.form.dailTime) {
        let dailTime = this.form.dailTime
        let dailTimeStart = dailTime[0].format("yyyy-MM-dd 00:00:00")
        let dailTimeEnd = dailTime[1].format("yyyy-MM-dd 23:59:59")
        let startD = new Date(Date.parse(dailTimeStart.replace(/-/g, "/")));
        let endD = new Date(Date.parse(dailTimeEnd.replace(/-/g, "/")));
        let days = parseInt((endD.getTime() - startD.getTime()) / (1000 * 60 * 60 * 24));
        if (days > 31) {
          this.$message.warning("时间跨度设置大于1个月，请重新设置导出时间跨度");
          return false;
        }

        this.form.startTime = dailTimeStart
        this.form.endTime = dailTimeEnd
        this.loading = true
        axios({
          method: "post",
          url: "/api/manage/crm/traffic/exportTrafficRecord",
          data: JSON.stringify(this.form),
          responseType: "blob",
          headers: {
            "Content-Type": "application/json;charset=utf-8",
            "version": localStorage.getItem("_version"),
          },
        }).then(res => {
          const blob = new Blob([res.data]);
          const fileName = '拨打记录.xlsx';
          const elink = document.createElement('a')
          elink.download = fileName;
          elink.style.display = 'none';
          elink.href = URL.createObjectURL(blob);
          document.body.appendChild(elink);
          elink.click();
          URL.revokeObjectURL(elink.href); // 释放URL 对象
          document.body.removeChild(elink);
          this.loading = false
        }).catch(error => {
          this.$message.error("导出拨打记录失败");
        })
      } else {
        this.$message.warning("拨打时间不可以为空")
      }


    }
  },
  mounted() {
    // window.addEventListener('keydown', this.keyboard, true)//开启监听键盘按下事件
    this.trafficRecordList()
    this.initAdviser()
    this.findDeptChild()
  }
}
</script>

<style scoped>

</style>
